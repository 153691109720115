import { Outlet } from "react-router-dom"

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";


const Layout = ({
  set_request_access_modal_is_open
} : {
  set_request_access_modal_is_open : any
}) => {


  
  return (
    <>
      <Navbar set_request_access_modal_is_open={set_request_access_modal_is_open} />
      <Outlet />
      <Footer />
    </>
  )
}

export default Layout;
