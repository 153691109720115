import yc_logo from "../media/investors/yc.png"
import kp_logo from "../media/investors/kp.png"
import gfc_logo from "../media/investors/gfc.png"


export const investors = {
  yc: {
    image: yc_logo,
    url: "https://www.ycombinator.com/"
  },
  kp: {
    image: kp_logo,
    url: "https://www.kleinerperkins.com/"
  },
  gfc: {
    image: gfc_logo,
    url: "https://www.globalfounderscapital.com/"
  }
}