import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";





const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode> // Remove strict mode so that components only render once
    <BrowserRouter>
      <App />
    </BrowserRouter>
  // </React.StrictMode>
);