import { useEffect, useState } from 'react'

import { useNavigate, Link } from "react-router-dom"

import third_party_risk_filter_image from "../../../../media/graphics/third_party_risk_filter_image.png"
import third_party_risk_conversation_image from "../../../../media/graphics/third_party_risk_conversation_image.png"

import BackgroundIndex from '../../../../components/BackgroundIndex'
import { mixpanel_client_track } from '../../../../libs/mixpanelClient'

// Tailwind
import {
  ChatBubbleLeftRightIcon,
  IdentificationIcon,
  CodeBracketSquareIcon,
  PlayCircleIcon,
  ShieldExclamationIcon,
} from '@heroicons/react/20/solid'
import { Switch, RadioGroup } from '@headlessui/react'



const third_party_risk_features = [
  {
    name: 'Simulate third party risk scenarios.',
    description:
      'Run simulations based on third party risk scenarios most relevant to your industry & use case.',
    icon: PlayCircleIcon,
  },
  {
    name: 'Custom domains.',
    description: 'Contact our support team to purchase and use custom domains for your simulation use cases.',
    icon: CodeBracketSquareIcon,
  },
  {
    name: 'Stress-test & mitigate.',
    description: 'Stress-test your security controls and business processes. Identify & mitigate gaps and weaknesses.',
    icon: ShieldExclamationIcon,
  },
]


const simulation_features = [
  {
    name: 'Multi-message & interactive.',
    description:
      'Vansec’s patent pending multi-message simulation leverages AI to dynamically engage users over multiple emails and responses.',
    icon: ChatBubbleLeftRightIcon,
  },
  {
    name: 'Hyper-personalized.',
    description: 'Our AI connects to employee data to deliver phishing simulations of varying difficulties, with emails and scenarios custom-generated for each employee.',
    icon: IdentificationIcon,
  },
  {
    name: 'Realistic simulation.',
    description: 'Safely stress-test your organization’s security posture against today’s evolving cybersecurity threats.',
    icon: PlayCircleIcon,
  },
]




const SolutionsUseCaseThirdPartyRiskPage = ({
  set_request_access_modal_is_open
} : {
  set_request_access_modal_is_open : any
}) => {


  // Navigate
  const navigate = useNavigate()


  useEffect(() => {

    // Mixpanel tracking
    mixpanel_client_track("www_solutions_use_case_third_party_risk_visited", null)
    
  }, [])

  


    

  return (
    <div id="top" className="bg-gray-900">

      {/* Hero */}
      <div className="relative isolate overflow-hidden">

        {/* Background */}
        <BackgroundIndex />
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          

          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Third party risk
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Stress-test your security controls against third party compromise.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                // to="https://calendly.com/vansec/vansec-discovery"
                // target="_blank"
                to="/get-started#top"
                // onClick={() => set_request_access_modal_is_open(true)}
                className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white cursor-pointer"
              >
                Get started
              </Link>
              <Link to="/?demo_is_active=true#demo" className="text-sm font-semibold leading-6 text-white">
                Interactive demo <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
        </div>

      </div>

      {/* Pentest section */}
      <div id="pentest" className="relative isolate overflow-hidden">
        <div className="mx-auto mt-4 max-w-7xl lg:mt-32 px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 ">
            
            <div className="mx-auto mt-16 flex max-w-2xl sm:mt-0 -ml-[50%] lg:-ml-[calc(100%+10rem)] lg:mt-0 lg:max-w-none lg:flex-none xl:-ml-[calc(100%+10rem)] lg:visible invisible h-0 lg:h-auto">
              <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                <img
                  src={third_party_risk_filter_image}
                  alt="Simulation"
                  width={2432}
                  height={1442}
                  className="w-[76rem] rounded-md"
                  // className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
                />
              </div>
            </div>

            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-indigo-400">Automated pentest</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Automated third party risk pentest</p>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  Stay up to date with the latest third party risk using our social engineering database. Run campaigns to stress-test your organization's security posture.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                  {third_party_risk_features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-white">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                        {feature.name}
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            
          </div>
        </div>
      </div>

      {/* Simulation section (conversation image on the right side) */}
      <div id="simulation" className="relative isolate overflow-hidden">
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-indigo-400">Vansec for third party risk</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">AI-powered advanced phishing simulation for third party risk</p>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  Multi-message conversational spear phishing simulation to stress-test your organization against third party risk.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                  {simulation_features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-white">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                        {feature.name}
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <div className="mx-auto mt-0 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-12">
              <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none lg:visible invisible h-0 lg:h-auto">
                <img
                  src={third_party_risk_conversation_image}
                  alt="Training"
                  width={2432}
                  height={1442}
                  className="w-[76rem] rounded-md"
                  // className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* CTA section */}
      <div className="relative isolate mt-32 px-6 py-32 sm:mt-56 sm:py-40 lg:px-8">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="1d4240dd-898f-445f-932d-e2872fd12de3"
              width={200}
              height={200}
              x="50%"
              y={0}
              patternUnits="userSpaceOnUse"
            >
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={0} className="overflow-visible fill-gray-800/20">
            <path
              d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#1d4240dd-898f-445f-932d-e2872fd12de3)" />
        </svg>
        <div
          className="absolute inset-x-0 top-10 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
            style={{
              clipPath:
                'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Next-gen phishing simulation & security awareness training platform
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
            Prepare your organization against evolving cybersecurity threats with realistic simulations & effective training, powered by AI. 
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              // to="https://calendly.com/vansec/vansec-discovery"
              // target="_blank"
              to="/get-started#top"
              // onClick={() => set_request_access_modal_is_open(true)}
              className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white cursor-pointer"
            >
              Get started
            </Link>
            <Link to="/?demo_is_active=true#demo" className="text-sm font-semibold leading-6 text-white">
              Interactive demo <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>

      
    </div>
  )
}



export default SolutionsUseCaseThirdPartyRiskPage