/* This example requires Tailwind CSS v3.0+ */
import { useState } from 'react'

// import { Link } from "react-router-dom"
import { HashLink as Link } from 'react-router-hash-link';

import { ReactComponent as TextLogo } from "../media/logos/text_logo_320x132.svg"




const navigation = {
  products: [
    { name: 'Vansec Simulation', to: '/products/vansec-simulation#top', target: "_self" },
    { name: 'Vansec Training', to: '/products/vansec-training#top', target: "_self" },
  ],
  solutions: [
    { name: 'Social Engineering', to: '/solutions/use-case/social-engineering#top', target: "_self" },
    { name: 'CEO Fraud', to: '/solutions/use-case/ceo-fraud#top', target: "_self" },
    { name: 'Business Email Compromise', to: '/solutions/use-case/business-email-compromise#top', target: "_self" },
    { name: 'Third Party Risk', to: '/solutions/use-case/third-party-risk#top', target: "_self" },
    { name: 'Smishing, Vishing, Deepfakes', to: '/solutions/use-case/smishing-vishing-deepfakes#top', target: "_self" },
    { name: 'Multi-Channel Spear Phishing', to: '/solutions/use-case/multi-channel-spear-phishing#top', target: "_self" },
  ],
  resources: [
    { name: 'About', to: '/about#top', target: "_self" },
    { name: 'Blog', to: 'https://blog.vansec.com', target: "_self" },
    { name: 'Contact Us', to: '/about#contact', target: "_self" },
  ],
  legal: [
    { name: 'Privacy', to: 'https://app.vansec.com/privacy', target: "_blank" },
    { name: 'Terms', to: 'https://app.vansec.com/terms', target: "_blank" },
  ]
}

const Footer = ({ 
  
}: {
  
}) => {
  
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <footer className="w-full bg-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <Link to="/#top" target="_self">
              <TextLogo className="h-4 w-auto" />
            </Link>
            <p className="text-sm leading-6 text-white">
              Phishing simulation & security awareness training powered by AI.
            </p>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">Products</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.products.map((item) => (
                    <li key={item.name}>
                      <Link to={item.to} target={item.target} className="text-sm leading-6 text-white hover:text-white">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                {/* <h3 className="text-sm font-semibold leading-6 text-white">Resources</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.resources.map((item) => (
                    <li key={item.name}>
                      <Link to={item.to} target={item.target} className="text-sm leading-6 text-white hover:text-white">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul> */}
                <h3 className="text-sm font-semibold leading-6 text-white">Solutions</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <Link to={item.to} target={item.target} className="text-sm leading-6 text-white hover:text-white">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">Resources</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.resources.map((item) => (
                    <li key={item.name}>
                      <Link to={item.to} target={item.target} className="text-sm leading-6 text-white hover:text-white">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">Legal</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link to={item.to} target={item.target} className="text-sm leading-6 text-white hover:text-white">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-white">&copy; 2024 PlusIdentity, Inc. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}


export default Footer;