


export const gtag_report_conversion = (url?) => {
  const callback = () => {
    if (typeof(url) !== 'undefined') {
      window.location = url;
    }
  };
  (window as any).gtag('event', 'conversion', {
    'send_to': 'AW-16667424076/FCv7CO-_oskZEMzq0os-',
    'value': 10.0,
    'currency': 'USD',
    'event_callback': callback
  });
  return false;
};


