import { useState, useEffect } from "react"

import ReactLoading from "react-loading"


// Routing
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

// Layouts
import Layout from "./layouts/Layout";

// Routes
import IndexPage from "./pages";
import AboutPage from "./pages/about";
import GetStartedPage from "./pages/get-started";
import PricingPage from "./pages/pricing";

import ProductsVansecSimulationPage from "./pages/products/vansec-simulation";
import ProductsVansecTrainingPage from "./pages/products/vansec-training";

import SolutionsOverviewHumanVulnerabilityManagementPage from "./pages/solutions/overview/human-vulnerability-management";
import SolutionsOverviewSolutionEngineeringDatabasePage from "./pages/solutions/overview/social-engineering-database";
import SolutionsOverviewMITREATTACKPage from "./pages/solutions/overview/mitre-attack";

import SolutionsUseCaseSocialEngineeringPage from "./pages/solutions/use-case/social-engineering";
import SolutionsUseCaseCEOFraudPage from "./pages/solutions/use-case/ceo-fraud";
import SolutionsUseCaseBusinessEmailCompromisePage from "./pages/solutions/use-case/business-email-compromise";
import SolutionsUseCaseThirdPartyRiskPage from "./pages/solutions/use-case/third-party-risk";
import SolutionsUseCaseSmishingVishingDeepfakesPage from "./pages/solutions/use-case/smishing-vishing-deepfakes";
import SolutionsUseCaseMultiChannelSpearPhishingPage from "./pages/solutions/use-case/multi-channel-spear-phishing";

import SolutionsForCSuitePage from "./pages/solutions/for/c-suite";
import SolutionsForFinancePage from "./pages/solutions/for/finance";
import SolutionsForCustomerSupportPage from "./pages/solutions/for/customer-support";
import SolutionsForHumanResourcesPage from "./pages/solutions/for/human-resources";
import SolutionsForOrganizationPage from "./pages/solutions/for/organization";



// Modals
import RequestAccessModal from "./components/RequestAccessModal";


// Other routes
import UnsubscribePage from "./pages/unsubscribe";
import Custom404 from "./pages/404";



// CSS
import "./App.css";

// Client environment
import { browserName, isMobile, isDesktop } from "react-device-detect";
import { scroll_to_anchor_tag_upon_refresh } from "./libs/helpers";






const App = () => {

  // Navigate
  const navigate = useNavigate()

  // Location 
  const location = useLocation();

  // States
  const [request_access_modal_is_open, set_request_access_modal_is_open] = useState(false)

  // Token verified
  const [session_is_checked, set_session_is_checked] = useState(false)

  // Awaiting
  const [awaiting, set_awaiting] = useState(true)



  // Upon load
  useEffect(() => {

    // Handle anchor tag upon refresh, if any
    scroll_to_anchor_tag_upon_refresh(location)
    
  }, [location])

  return (
    <>
      {/* Routes */}
      <Routes>
        <Route element={<Layout set_request_access_modal_is_open={set_request_access_modal_is_open} />}>
          <Route 
            path="/" 
            element={
              <IndexPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />
          <Route 
            path="about" 
            element={
              <AboutPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />
          <Route 
            path="get-started" 
            element={
              <GetStartedPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />


          {/* <Route 
            path="pricing" 
            element={
              <PricingPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          /> */}

          {/* Products */}
          <Route 
            path="products/vansec-simulation" 
            element={
              <ProductsVansecSimulationPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />
          <Route 
            path="products/vansec-training" 
            element={
              <ProductsVansecTrainingPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />

          {/* Solutions */}

          {/* overview */}
          <Route 
            path="solutions/overview/human-vulnerability-management" 
            element={
              <SolutionsOverviewHumanVulnerabilityManagementPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />
          <Route 
            path="solutions/overview/social-engineering-database" 
            element={
              <SolutionsOverviewSolutionEngineeringDatabasePage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />
          <Route 
            path="solutions/overview/mitre-attack" 
            element={
              <SolutionsOverviewMITREATTACKPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />

          {/* use case */}
          <Route 
            path="solutions/use-case/social-engineering" 
            element={
              <SolutionsUseCaseSocialEngineeringPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />
          <Route 
            path="solutions/use-case/ceo-fraud" 
            element={
              <SolutionsUseCaseCEOFraudPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />
          <Route 
            path="solutions/use-case/business-email-compromise" 
            element={
              <SolutionsUseCaseBusinessEmailCompromisePage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />
          <Route 
            path="solutions/use-case/third-party-risk" 
            element={
              <SolutionsUseCaseThirdPartyRiskPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />
          <Route 
            path="solutions/use-case/smishing-vishing-deepfakes" 
            element={
              <SolutionsUseCaseSmishingVishingDeepfakesPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />
          <Route 
            path="solutions/use-case/multi-channel-spear-phishing" 
            element={
              <SolutionsUseCaseMultiChannelSpearPhishingPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />
          
          {/* for */}
          <Route 
            path="solutions/for/c-suite" 
            element={
              <SolutionsForCSuitePage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />
          <Route 
            path="solutions/for/finance" 
            element={
              <SolutionsForFinancePage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />
          <Route 
            path="solutions/for/customer-support" 
            element={
              <SolutionsForCustomerSupportPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />
          <Route 
            path="solutions/for/human-resources" 
            element={
              <SolutionsForHumanResourcesPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />
          <Route 
            path="solutions/for/organization" 
            element={
              <SolutionsForOrganizationPage 
                set_request_access_modal_is_open={set_request_access_modal_is_open} 
              />
            } 
          />

          



        </Route>

        <Route path="unsubscribe" element={<UnsubscribePage />} />
        <Route path="*" element={<Custom404 />} />
      </Routes>

      {/* Modals */}
      <RequestAccessModal 
        is_open={request_access_modal_is_open}
        set_is_open={set_request_access_modal_is_open}
      />


    </>
  );
}

export default App;
