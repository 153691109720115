



export const LetterMIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <text x="4" y="20" fontFamily="Arial" fontSize="20" fill="currentColor">M</text>
  </svg>
);


export const RobotIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-4-9h2v2h-2v-2zm8 0h2v2h-2v-2zm-4 4c-2.21 0-4 1.79-4 4h8c0-2.21-1.79-4-4-4zm0-1c1.66 0 3-1.34 3-3H9c0 1.66 1.34 3 3 3z"/>
  </svg>
);