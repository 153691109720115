


export const sleep = function (duration: number) {
	return new Promise<void>(resolve => {
		setTimeout(() => {
			resolve()
		}, duration * 1000)
	})
}

export const handle_enter_keydown = (e: any, callback: any) => {
	//it triggers by pressing the enter key
	if (e.key === 'Enter') {
		callback();
	}
}



// Convert react-detect-device name to our naming convention
export const get_browser_name = (raw_name: string) => {
  switch(raw_name) {
    case "Firefox": {
      return "firefox"
    }
    case "Edge": {
      return "edge"
    }
    case "Chrome": {
      return "chrome"
    }
    default: {
      return "error"
    }
  }
}

// Convert our naming convention to pretty names
export const get_browser_name_pretty = (browser_name: string) => {
  switch(browser_name) {
    case "firefox": {
      return "Firefox"
    }
    case "edge": {
      return "Edge"
    }
    case "chrome": {
      return "Chrome"
    }
    default: {
      return "error"
    }
  }
}



export const scroll_to_anchor_tag_upon_refresh = (location) => {
  const hash = location.hash;
  if (hash) {
    const element = document.getElementById(hash.replace('#', ''));
    if (element) {
      element.scrollIntoView();
    }
  }
}

