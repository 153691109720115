import { useEffect, useState } from 'react'

import { useNavigate, Link } from "react-router-dom"

import ReactLoading from "react-loading"

import simulation_image from "../../media/graphics/simulation_image.png"
import training_image from "../../media/graphics/training_image.png"

import BackgroundIndex from '../../components/BackgroundIndex'
import { mixpanel_client_track } from '../../libs/mixpanelClient'

// Tailwind
import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/20/solid'
import { Switch, RadioGroup } from '@headlessui/react'
import { validate_email_address, validate_organization_domain } from '../../libs/validate'

import axios from 'axios'
import { gtag_report_conversion } from '../../libs/gtag'




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



const Calendly = () => {

  const [script_is_loaded, set_script_is_loaded] = useState(false)

  useEffect(() => {

    if (!script_is_loaded) {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      head.appendChild(script);
      set_script_is_loaded(true)
    }
  }, [])

  return (
    <div
      className="calendly-inline-widget"
      data-url="https://calendly.com/vansec/vansec-discovery?hide_event_type_details=1&hide_gdpr_banner=1"
      style={{ minWidth: '220px', height: '420px' }}
    ></div>
  );
};


const GetStartedPage = ({
  set_request_access_modal_is_open
} : {
  set_request_access_modal_is_open : any
}) => {


  // Navigate
  const navigate = useNavigate()

  // FORM
  const [full_name, set_full_name] = useState("")
  const [email_address, set_email_address] = useState("")
  const [organization, set_organization] = useState("")
  const [is_agreed, set_is_agreed] = useState(false)
  
  // Status 
  const [error_message, set_error_message] = useState("")
  const [is_awaiting, set_is_awaiting] = useState(false)
  const [scheduling_is_shown, set_scheduling_is_shown] = useState(false)









  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "full_name": {
        set_full_name(value)

        // Always break
        break
      }
      case "email_address": {
        set_email_address(value)

        // Always break
        break
      }
      case "organization": {
        set_organization(value)

        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }

  const form_to_scheduling = async () => {

    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate email address
    if (!validate_email_address(email_address)) {

      // Show error message
      set_error_message("Invalid email address")

      // Toggle
      set_is_awaiting(false)

      // End of the line
      return
    }

    const organization_domain = email_address.split("@")[1]

    // Validate email address
    if (!validate_organization_domain(organization_domain)) {

      // Show error message
      set_error_message("Please enter your work email")

      // Toggle
      set_is_awaiting(false)

      // End of the line
      return
    }

    // Submit request to server
    const post_request_res = await axios.post(`/api/index/form`, {
      type: "get_started",
      data: {
        full_name: full_name,
        email_address: email_address,
        organization: organization,
      }
    })

    if (!post_request_res.data.success) {
      // Show error message
      set_error_message("Please refresh and try again")

      // Toggle
      set_is_awaiting(false)

      // End of the line
      return
    }
    
    // // Wait 1 seconds
    // await sleep(1)

    // Move to scheduling page
    set_scheduling_is_shown(true)

    // Toggle
    set_is_awaiting(false)
    
    // Mixpanel tracking
    mixpanel_client_track("www_get_started_form_submitted", null)

    // Google conversion tracking
    gtag_report_conversion()

  }


  useEffect(() => {

    // Mixpanel tracking
    mixpanel_client_track("www_get_started_visited", null)
    
  }, [])

  


    

  return (
    <div id="top" className="bg-gray-900">

      {/* Hero */}
      <div className="relative isolate overflow-hidden">

        {/* Background */}
        <BackgroundIndex />
        <div className="mx-auto max-w-4xl pt-36 pb-96 lg:py-60 flex lg:space-x-4 flex-col lg:flex-row lg:space-x-12 items-center">


          {/* Left side */}
          <div className="text-white leading-tight flex flex-col lg:items-start px-4 mb-12">
            <div className="text-4xl lg:text-6xl font-semibold ">
              Let's get started
            </div>
            <div className=" font-semibold text-gray-400 text-md lg:text-xl mt-4">
              Your white-glove experience begins here.
            </div>
          </div>
          

          {/* Right side */}
          <div className="relative flex-none lg:max-w-none lg:visible h-[100px] w-full lg:w-3/5">
            
            {/* Form */}
            <div className={classNames(scheduling_is_shown ? "opacity-0" : "opacity-100 z-10", "absolute lg:-top-28 space-y-6 px-12 sm:px-36 lg:px-12")}>
              {/* Input fields */}
              <div className="space-y-4">
                <div>
                  <div className="block text-sm font-medium leading-6 text-white">
                    Full name
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      autoComplete="name"
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3 outline-none placeholder:text-white/30"
                      // placeholder="John Doe"
                      value={full_name}
                      onChange={(e) => handle_user_input("full_name", e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <div className="block text-sm font-medium leading-6 text-white">
                    Email address
                  </div>
                  <div className="mt-2">
                    <input
                      type="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3 outline-none placeholder:text-white/30"
                      // placeholder="john@acme.com"
                      value={email_address}
                      onChange={(e) => handle_user_input("email_address", e.target.value)}
                    />
                  </div>
                  {email_address
                  ? <div className="mt-2 block text-sm font-medium leading-6 text-gray-400">
                    Please make sure to provide your work email.
                  </div>
                  : <></>}
                </div>

                <div>
                  <div className="block text-sm font-medium leading-6 text-white">
                    Organization name
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      autoComplete="organization"
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3 outline-none placeholder:text-white/30"
                      // placeholder="Acme Inc."
                      value={organization}
                      onChange={(e) => handle_user_input("organization", e.target.value)}
                    />
                  </div>
                </div>

              </div>
              
              {/* Bottom line */}
              <div className="flex space-x-4">

                {/* Terms & policies */}
                <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
                  <div className="flex h-6 items-center">
                    <Switch
                      checked={is_agreed}
                      onChange={set_is_agreed}
                      className={classNames(
                        is_agreed ? 'bg-indigo-500' : 'bg-white/10',
                        'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'
                      )}
                    >
                      <span className="sr-only">Agree to policies</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          is_agreed ? 'translate-x-3.5' : 'translate-x-0',
                          'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </div>
                  <div>
                    <Switch.Label className="text-sm leading-6 text-gray-400 cursor-pointer">
                      By selecting this, you agree to our{' '}
                    </Switch.Label>
                    <Link to="https://app.vansec.com/terms" target="_blank" className="font-semibold text-indigo-400 text-sm leading-6">
                      Terms&nbsp;of&nbsp;Service
                    </Link>
                    <Switch.Label className="text-sm leading-6 text-gray-400 cursor-pointer">
                      {" "}and{" "}
                    </Switch.Label>
                    <Link to="https://app.vansec.com/privacy" target="_blank" className="font-semibold text-indigo-400 text-sm leading-6">
                      Privacy&nbsp;Policy
                    </Link>
                    <Switch.Label className="text-sm leading-6 text-gray-400 cursor-pointer">
                      .
                    </Switch.Label>
                  </div>

                  
                
                  
                </Switch.Group>

                <div className="flex justify-center items-center">
                  <button
                    type="submit"
                    disabled={!(full_name && email_address && organization && is_agreed)}
                    className={classNames(
                      full_name && email_address && organization && is_agreed ? 'bg-indigo-500 hover:bg-indigo-400' : 'bg-white/5 hover:bg-white/10',
                      'block w-full rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'
                    )}
                    onSubmit={form_to_scheduling} 
                    onClick={form_to_scheduling}
                  >
                    {is_awaiting
                    ? <ReactLoading 
                      type='spokes'
                      color='#FFFFFF'
                      height={20}
                      width={20}
                    />
                    : "Continue"}
                  </button>
                </div>
                
                {/* <ArrowRightCircleIcon className="w-12 h-12 text-white/30" /> */}

              </div>

              {/* Error message */}
              {error_message
              ? <div className="mt-6 flex space-x-2 items-start">
                  <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
                  <div className="text-sm font-medium text-red-400">{error_message}</div>
                </div>
              : <></>}
            </div>

            {/* Scheduling */}
            <div className={classNames(scheduling_is_shown ? "opacity-100 z-10" : "opacity-0", "relative flex flex-col w-full h-content items-center")}>
              <div className="text-sm font-normal text-white px-12 sm:px-24 lg:px-12 lg:pt-[280px] -mt-6 sm:mt-0">
              Thank you! Our representative will reach out to you shortly. You can also book your demo now.
              </div>
              <div className="absolute top-12 lg:-top-40 mx-12 rounded-md w-auto sm:w-[456px] overflow-hidden bg-white shadow-glow focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50">
                <Calendly />
              </div>
              
            </div>

            

          </div>


          

          
          
          
          
        </div>

      </div>


      {/* CTA section */}
      <div className="relative isolate mt-32 px-6 py-32 sm:mt-56 sm:py-40 lg:px-8">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="1d4240dd-898f-445f-932d-e2872fd12de3"
              width={200}
              height={200}
              x="50%"
              y={0}
              patternUnits="userSpaceOnUse"
            >
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={0} className="overflow-visible fill-gray-800/20">
            <path
              d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#1d4240dd-898f-445f-932d-e2872fd12de3)" />
        </svg>
        <div
          className="absolute inset-x-0 top-10 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
            style={{
              clipPath:
                'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Next-gen phishing simulation & security awareness training platform
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
            Prepare your organization against evolving cybersecurity threats with realistic simulations & effective training, powered by AI. 
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              // to="https://calendly.com/vansec/vansec-discovery"
              // target="_blank"
              to="/get-started#top"
              // onClick={() => set_request_access_modal_is_open(true)}
              className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white cursor-pointer"
            >
              Get started
            </Link>
            <Link to="/?demo_is_active=true#demo" className="text-sm font-semibold leading-6 text-white">
              Interactive demo <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>

      
    </div>
  )
}



export default GetStartedPage