import { concat_uint8_arrays } from './converters'

const IV_LENGTH_BYTES = 12; // in bytes
const AES_KEY_LENGTH_BITS = 256; // in bits; divide by 8 to get num_bytes

// INPUT
// num_bytes : Integer
// OUTPUT
// values__uint8_array : Uint8Array
export const CSPRNG = (num_bytes) => {
  const values__uint8_array = window.crypto.getRandomValues(new Uint8Array(num_bytes));

  return values__uint8_array;
}



// INPUT
// data__uint8_array : Uint8Array
// OUTPUT
// hash__uint8_array : Uint8Array
export const HASH = async (data__uint8_array) => {
  const hash__uint8_array = await window.crypto.subtle.digest('SHA-256', data__uint8_array);

  return hash__uint8_array;
}


// INPUT
// key_base__uint8_array : Uint8Array
// salt__uint8_array : Uint8Array
// OUTPUT
// key_derived__uint8_array : Uint8Array
export const PBKDF2 = async (key_base__uint8_array, salt__uint8_array) => {
  let key_base__crypto_key = await window.crypto.subtle.importKey(
    "raw",
    key_base__uint8_array,
    'PBKDF2',
    false,
    ['deriveKey']
  );

  let key_derived__crypto_key = await window.crypto.subtle.deriveKey({
    name: 'PBKDF2',
    salt: salt__uint8_array,
    iterations: 101004,
    hash: {name: 'SHA-256'}
  }, key_base__crypto_key, {name: 'AES-GCM', length: AES_KEY_LENGTH_BITS}, true, ['encrypt']);

  let key_derived__array_buffer = await window.crypto.subtle.exportKey('raw', key_derived__crypto_key);
  let key_derived__uint8_array = new Uint8Array(key_derived__array_buffer);

  return key_derived__uint8_array;
}

// INPUT
// key_base__uint8_array : Uint8Array
// salt__uint8_array : Uint8Array
// info__uint8_array : Uint8Array
// OUTPUT
// key_derived__uint8_array : Uint8Array
export const HKDF = async (key_base__uint8_array, salt__uint8_array, info__uint8_array) => {
  let key_base__crypto_key = await window.crypto.subtle.importKey(
    "raw",
    key_base__uint8_array,
    'HKDF',
    false,
    ['deriveKey']
  );

  let key_derived__crypto_key = await window.crypto.subtle.deriveKey({
    name: 'HKDF',
    salt: salt__uint8_array,
    hash: {name: 'SHA-256'},
    info: info__uint8_array
  }, key_base__crypto_key, {name: 'AES-GCM', length: AES_KEY_LENGTH_BITS}, true, ['encrypt']);

  let key_derived__array_buffer = await window.crypto.subtle.exportKey('raw', key_derived__crypto_key);
  let key_derived__uint8_array = new Uint8Array(key_derived__array_buffer);

  return key_derived__uint8_array;
}

// INPUT
// a__uint8_array : Uint8Array
// b__uint8_array : Uint8Array
// OUTPUT
// result__uint8_array : Uint8Array
// Note: if one or both of the input is length greater than 32, only the first 32 bytes of that array(s) will be taken into account. If both are of length lesser than 32, the output will be of the length of the longer array.
export const XOR = (a__uint8_array, b__uint8_array) => {

  let length = Math.min(Math.max(a__uint8_array.length, b__uint8_array.length), AES_KEY_LENGTH_BITS/8);

  let result__uint8_array = new Uint8Array(length);

  for (let i=0; i<length; i++) {
    result__uint8_array[i] = a__uint8_array[i] ^ b__uint8_array[i];
  }
  
  return result__uint8_array;
}

// INPUT
// (none)
// OUTPUT
// [private_key__uint8_array, public_key__uint8_array] : [Uint8Array, Uint8Array]
export const GEN_KEY_PAIR = async () => {
  let key_pair = await window.crypto.subtle.generateKey({
      name: "RSA-OAEP",
      modulusLength: 4096,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: 'SHA-256'
    }, true, ['encrypt', 'decrypt']
  );

  let private_key__crypto_key = key_pair.privateKey;
  let public_key__crypto_key = key_pair.publicKey;

  let private_key__array_buffer = await window.crypto.subtle.exportKey('pkcs8', private_key__crypto_key);
  let private_key__uint8_array = new Uint8Array(private_key__array_buffer);

  let public_key__array_buffer = await window.crypto.subtle.exportKey('spki', public_key__crypto_key);
  let public_key__uint8_array = new Uint8Array(public_key__array_buffer);

  return [private_key__uint8_array, public_key__uint8_array];
}

// INPUT
// key__uint8_array : Uint8Array
// raw_data__uint8_array : Uint8Array
// OUTPUT
// encrypted_data_package__uint8_array : Uint8Array
export const SYM_ENCRYPT = async (key__uint8_array, raw_data__uint8_array) => {
  let key__crypto_key = await window.crypto.subtle.importKey(
    "raw",
    key__uint8_array,
    'AES-GCM',
    false,
    ['encrypt']
  );

  const iv__uint8_array = CSPRNG(IV_LENGTH_BYTES);

  let encrypted_data__array_buffer = await window.crypto.subtle.encrypt({
    name: 'AES-GCM',
    iv: iv__uint8_array
  }, key__crypto_key, raw_data__uint8_array)

  let encrypted_data__uint8_array = new Uint8Array(encrypted_data__array_buffer);

  let encrypted_data_package__uint8_array = concat_uint8_arrays(
    iv__uint8_array,
    encrypted_data__uint8_array
  )

  return encrypted_data_package__uint8_array;
}

// INPUT
// key__uint8_array : Uint8Array
// encrypted_data_package__uint8_array : Uint8Array
// OUTPUT
// raw_data__uint8_array : Uint8Array
export const SYM_DECRYPT = async (key__uint8_array, encrypted_data_package__uint8_array) => {
  const iv__uint8_array = encrypted_data_package__uint8_array.slice(0, IV_LENGTH_BYTES);
  const encrypted_data__uint8_array = encrypted_data_package__uint8_array.slice(IV_LENGTH_BYTES);

  let key__crypto_key = await window.crypto.subtle.importKey(
    "raw",
    key__uint8_array,
    'AES-GCM',
    false,
    ['decrypt']
  );

  let raw_data__array_buffer = await window.crypto.subtle.decrypt({
    name: 'AES-GCM',
    iv: iv__uint8_array
  }, key__crypto_key, encrypted_data__uint8_array);

  let raw_data__uint8_array = new Uint8Array(raw_data__array_buffer);
  return raw_data__uint8_array;
}

// INPUT
// public_key__uint8_array : Uint8Array
// raw_data__uint8_array : Uint8Array
// OUTPUT
// encrypted_data__uint8_array : Uint8Array
export const ASYM_ENCRYPT = async (public_key__uint8_array, raw_data__uint8_array) => {
  let key__crypto_key = await window.crypto.subtle.importKey(
    "spki",
    public_key__uint8_array,
    {name: 'RSA-OAEP', hash: 'SHA-256'},
    false,
    ['encrypt']
  );

  let encrypted_data__array_buffer = await window.crypto.subtle.encrypt({
    name: 'RSA-OAEP'
  }, key__crypto_key, raw_data__uint8_array)

  let encrypted_data__uint8_array = new Uint8Array(encrypted_data__array_buffer);

  return encrypted_data__uint8_array;
}

// INPUT : Uint8Array
// private_key__uint8_array : Uint8Array
// encrypted_data__uint8_array : Uint8Array
// OUTPUT
// raw_data__uint8_array : Uint8Array
export const ASYM_DECRYPT = async (private_key__uint8_array, encrypted_data__uint8_array) => {
  let key__crypto_key = await window.crypto.subtle.importKey(
    "pkcs8",
    private_key__uint8_array,
    {name: 'RSA-OAEP', hash: 'SHA-256'},
    false,
    ['decrypt']
  );

  let raw_data__array_buffer = await window.crypto.subtle.decrypt({
    name: 'RSA-OAEP'
  }, key__crypto_key, encrypted_data__uint8_array);

  let raw_data__uint8_array = new Uint8Array(raw_data__array_buffer);
  return raw_data__uint8_array;
}