import { useEffect, useState } from 'react'

import { useNavigate, Link } from "react-router-dom"


import { investors } from "../../data/investors"
import { team } from "../../data/team"

import BackgroundIndex from '../../components/BackgroundIndex'
import { mixpanel_client_track } from '../../libs/mixpanelClient'


const AboutPage = ({
  set_request_access_modal_is_open
} : {
  set_request_access_modal_is_open : any
}) => {


  // Navigate
  const navigate = useNavigate()


  useEffect(() => {

    // Mixpanel tracking
    mixpanel_client_track("www_about_visited", null)
    
  }, [])

  


    

  return (
    <div id="top" className="bg-gray-900">

      {/* Hero */}
      <div className="relative isolate overflow-hidden">

        {/* Background */}
        <BackgroundIndex />
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          

          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Our mission
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Empowering organizations against fast-evolving phishing threats.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                // to="https://calendly.com/vansec/vansec-discovery"
                // target="_blank"
                to="/get-started#top"
                // onClick={() => set_request_access_modal_is_open(true)}
                className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white cursor-pointer"
              >
                Get started
              </Link>
              <Link to="/?demo_is_active=true#demo" className="text-sm font-semibold leading-6 text-white">
                Interactive demo <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
        </div>

      </div>

      {/* Investors */}
      <div className="py-4 sm:py-8">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-center text-lg font-semibold leading-8 text-white">
            Backed by
          </h2>
          <div className="mx-auto mt-10 grid max-w-xl grid-cols-2 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <Link to={investors.kp.url} target="_blank" className="col-span-2 max-h-7 w-full object-contain lg:col-span-1 hover:scale-105 transition ease-in-out">
              <img
                className="max-h-7 w-full object-contain"
                src={investors.kp.image}
                
                alt="Transistor"
              />
            </Link>
            <Link to={investors.yc.url} target="_blank" className="col-span-2 max-h-14 w-full object-contain lg:col-span-1 hover:scale-105 transition ease-in-out">
              <img
                className="max-h-14 w-full object-contain"
                src={investors.yc.image}
                
                alt="Transistor"
              />
            </Link>
            <Link to={investors.gfc.url} target="_blank" className="col-span-2 max-h-6 w-full object-contain lg:col-span-1 hover:scale-105 transition ease-in-out">
              <img
                className="max-h-6 w-full object-contain"
                src={investors.gfc.image}
                
                alt="Transistor"
              />
            </Link>
          </div>
        </div>
      </div>

      {/* Team section */}
      <div className="py-24 md:py-32">
        <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-16 lg:px-8 xl:grid-cols-5">
          <div className="max-w-2xl xl:col-span-2">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Our team</h2>
            {/* <p className="mt-6 leading-7 text-gray-300">
              Say hello to our team.
            </p> */}
          </div>
          <ul role="list" className="-mt-12 space-y-12 divide-y divide-gray-800 xl:col-span-3">
            {team.map((person) => (
              <li key={person.name} className="flex flex-col gap-10 pt-12 sm:flex-row">
                <img className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" src={person.image} alt="" />
                <div className="max-w-xl flex-auto">
                  <h3 className="text-lg font-semibold leading-8 tracking-tight text-white">{person.name}</h3>
                  <p className="text-base leading-7 text-gray-300">{person.role}</p>
                  <p className="mt-6 text-base leading-7 text-gray-300">{person.bio}</p>
                  <ul role="list" className="mt-6 flex gap-x-6">
                    <li>
                      <Link to={person.linkedinUrl} target="_blank" className="text-gray-300 hover:text-gray-400">
                        <span className="sr-only">LinkedIn</span>
                        <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                          <path
                            fillRule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Contact us */}
      <div id="contact" className="py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-800 lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-16 lg:grid-cols-3">
              <div>
                <h2 className="text-3xl font-bold tracking-tight text-white">Get in touch</h2>
              </div>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                <div className="rounded-2xl bg-gray-800 p-10 ring-1 ring-white/20">
                  <h3 className="text-base font-semibold leading-7 text-white">Say hello</h3>
                  <dl className="mt-3 space-y-1 text-sm leading-6">
                    <div>
                      <dt className="sr-only">Email</dt>
                      <dd>
                        <Link className="font-semibold text-gray-300" to="mailto:info@vansec.com">
                          info@vansec.com
                        </Link>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="rounded-2xl bg-gray-800 p-10 ring-1 ring-white/20">
                  <h3 className="text-base font-semibold leading-7 text-white">Investor relations</h3>
                  <dl className="mt-3 space-y-1 text-sm leading-6">
                    <div>
                      <dt className="sr-only">Email</dt>
                      <dd>
                        <Link className="font-semibold text-gray-300" to="mailto:info@vansec.com">
                          info@vansec.com
                        </Link>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="rounded-2xl bg-gray-800 p-10 ring-1 ring-white/20">
                  <h3 className="text-base font-semibold leading-7 text-white">Careers</h3>
                  <dl className="mt-3 space-y-1 text-sm leading-6">
                    <div>
                      <dt className="sr-only">Email</dt>
                      <dd>
                        <Link className="font-semibold text-gray-300" to="mailto:careers@vansec.com">
                          careers@vansec.com
                        </Link>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="rounded-2xl bg-gray-800 p-10 ring-1 ring-white/20">
                  <h3 className="text-base font-semibold leading-7 text-white">Questions</h3>
                  <dl className="mt-3 space-y-1 text-sm leading-6">
                    <div>
                      <dt className="sr-only">Email</dt>
                      <dd>
                        <Link className="font-semibold text-gray-300" to="mailto:support@vansec.com">
                          support@vansec.com
                        </Link>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-16 pt-16 lg:grid-cols-3">
              <div>
                <h2 className="text-3xl font-bold tracking-tight text-white">Locations</h2>
              </div>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                <div className="rounded-2xl bg-gray-800 p-10 ring-1 ring-white/20">
                  <h3 className="text-base font-semibold leading-7 text-white">New York</h3>
                  <address className="mt-3 space-y-1 text-sm not-italic leading-6 text-gray-300">
                    <p>169 Madison Ave #2237</p>
                    <p>New York, NY 10016</p>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      
    </div>
  )
}



export default AboutPage