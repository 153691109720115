

import React, { useState, useRef, useEffect } from 'react'
import { validate_email_address, validate_organization_domain } from '../libs/validate'

import { Switch, RadioGroup } from '@headlessui/react'

import axios from 'axios'

import { Link } from "react-router-dom";

import { InformationCircleIcon, CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { CSPRNG } from '../libs/crypto';
import { uint8_array_to_base64 } from '../libs/converters';
import { delete_socket, get_socket } from '../libs/socket';
import { mixpanel_client_track } from '../libs/mixpanelClient'




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const adjust_height = (event) => {
  event.target.style.height = 'auto';
  event.target.style.height = `${event.target.scrollHeight}px`;
};

const simulation_scenario_options = [
  "ceo_urgent_wire",
  "aws_invoice_not_settled",
  "fedex_address_info_missing",
  "it_strange_login_behavior",
]

const simulation_scenario_map = {
  "ceo_urgent_wire": {
    title: "Urgent wire transfer",
    description: "Imagine you are an employee in your organization's finance department, and you receive an email from your CEO asking you to urgently wire transfer money to a specified bank account.",
    subject_line: "[Urgent] Deal closure help",
  },
  "aws_invoice_not_settled": {
    title: "Invoice not settled",
    description: "Imagine you are an employee in your organization's accounts department, and you receive an email from a vendor claiming that the latest invoice has not been settled.",
    subject_line: "Action required - invoice not settled"
  },
  "fedex_address_info_missing": {
    title: "Address info missing",
    description: "Imagine you are an employee in your organization, and you receive an email from Fedex claiming that address information is missing for an important package and they need you to confirm some details.",
    subject_line: "Action required - address info missing"
  },
  "it_strange_login_behavior": {
    title: "Strange login behavior",
    description: "Imagine you are an employee in your organization, and you receive an email from the IT department claiming that there was a strange login behavior last night with your account between 12:00 AM and 2:00 AM.",
    subject_line: "Action required - strange login behavior"
  },
}

const get_simulation_email_address = (email_address, scenario) => {
  const organization_domain = email_address.split("@")[1]

  const fake_organization_domain = organization_domain.slice(0, -1)

  switch (scenario) {
    case "ceo_urgent_wire": {
      return `debbie@${fake_organization_domain}`
    }
    case "aws_invoice_not_settled": {
      return `debbie.parsons@aws-admin.com` 
    }
    case "fedex_address_info_missing": {
      return `debbie.parsons@fedex-admin.com`
    }
    case "it_strange_login_behavior": {
      return `debbie@it-${organization_domain}` 
    }
    default: {
      return `debbie@${fake_organization_domain}`
    }
  }
}


const Demo = ({
  
} : {
  
}) => {

  const [page, set_page] = useState(0) // 0: form / 1: scenario / 2: main
  const [token, set_token] = useState("")
  const [cipher, set_cipher] = useState("")

  // FORM
  const [full_name, set_full_name] = useState("")
  const [email_address, set_email_address] = useState("")
  const [organization, set_organization] = useState("")
  const [position, set_position] = useState("")
  const [is_agreed, set_is_agreed] = useState(false)

  // SCENARIO
  const [scenario, set_scenario] = useState("ceo_urgent_wire") // "ceo_urgent_wire", "aws_invoice_not_settled", "fedex_address_info_missing", "it_strange_login_behavior"

  // MAIN
  const [conversation, set_conversation] = useState([])
  const [stream_output, set_stream_output] = useState("")
  const [demo_input, set_demo_input] = useState("")

  const scroll_ref = useRef(null)
  const demo_input_ref = useRef(null)
  
  // Status 
  const [is_streaming, set_is_streaming] = useState(false)
  const [error_message, set_error_message] = useState("")
  const [is_awaiting, set_is_awaiting] = useState(false)



  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "full_name": {
        set_full_name(value)

        // Always break
        break
      }
      case "email_address": {
        set_email_address(value)

        // Always break
        break
      }
      case "organization": {
        set_organization(value)

        // Always break
        break
      }
      case "position": {
        set_position(value)

        // Always break
        break
      }
      case "scenario": {
        set_scenario(value)

        // Always break
        break
      } 
      case "demo_input": {
        set_demo_input(value)

        // Always break
        break
      }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
  }

  const form_to_scenario = async () => {


    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate email address
    if (!validate_email_address(email_address)) {

      // Show error message
      set_error_message("Invalid email address")

      // Toggle
      set_is_awaiting(false)

      // End of the line
      return
    }

    const organization_domain = email_address.split("@")[1]

    // Validate email address
    if (!validate_organization_domain(organization_domain)) {

      // Show error message
      set_error_message("Please enter your work email")

      // Toggle
      set_is_awaiting(false)

      // End of the line
      return
    }

    // Generate token
    const token__uint8_array = CSPRNG(32)
    const token__base64 = uint8_array_to_base64(token__uint8_array)

    // Save token in frontend
    set_token(token__base64)

    // Submit request to server
    const post_request_res = await axios.post(`/api/index/form`, {
      type: "demo_form",
      token: token__base64,
      data: {
        full_name: full_name,
        email_address: email_address,
        organization: organization,
        position: position,
      }
    })

    if (!post_request_res.data.success) {
      // Show error message
      set_error_message("Please refresh and try again")

      // Toggle
      set_is_awaiting(false)

      // End of the line
      return
    }

    // Save cipher in frontend
    set_cipher(post_request_res.data.cipher)

    // Move to scenario page
    set_page(1)

    // Mixpanel tracking
    mixpanel_client_track("www_demo_form_submitted", null)
  }

  const scenario_to_main = async () => {

    // Move to main page
    set_page(2)

    // Send first text
    send_text(true)
  }

  const send_text = (is_first_message) => {

    let new_conversation = []

    // Build and set conversation and reset demo_input
    if (!is_first_message) {
      // Add demo_input to conversation
      new_conversation = [...conversation, {role: "user", content: demo_input}]
      set_conversation(new_conversation)
      set_demo_input("")
    }

    // Toggle is_streaming
    set_is_streaming(true)

    const socket = get_socket()
    socket.on('connect', () => {
      console.log(`Connected to socket at ${socket.id}`)
      try {
        // Send the text
        socket.emit("message", {
          type: "text",
          data: {
            token: token,
            cipher: cipher,
            full_name: full_name,
            organization: organization,
            position: position,
            scenario: scenario,
            conversation: new_conversation,
          }
        });

        let stream_output_buffer = ""

        // Listen for messages from socket
        socket.on('message', async (socket_message) => {
          switch(socket_message.type) {
            case "DELTA": {
              set_stream_output((previous_state) => previous_state + socket_message.delta)
              stream_output_buffer += socket_message.delta
              // set_stream_output("test")

              // Always break
              break
            }
            case "STOP": {
              // Add stream_output_buffer to conversation and reset stream_output
              set_conversation([...new_conversation, {role: "assistant", content: stream_output_buffer}])
              set_stream_output("")
              stream_output_buffer = ""

              // Toggle is_streaming
              set_is_streaming(false)

              // Disconnect from the socket upon being done
              socket.disconnect() 

              // Delete socket
              delete_socket()

              // Always break
              break
            }
            case "ERROR": {
              console.log("ERROR")

              // Reset stream_output
              set_stream_output("")
              stream_output_buffer = ""

              // Toggle is_streaming
              set_is_streaming(false)

              // Disconnect from the socket upon error
              socket.disconnect() 

              // Delete socket
              delete_socket()

              // Always break
              break
            }
            case "___": {

              // Always break
              break
            }
            default: {
              // Always break
              break
            }
          }
        })

        socket.on('disconnect', () => {
          console.log("Socket connection disconnected")
        })

        
        
        // // Toggle is_streaming
        // set_is_streaming(false)

      }
      catch (error) {
        console.log(error)
        // Disconnect from the socket upon error
        socket.disconnect() 
        // Delete socket
        delete_socket()
      }
    })
  }

  // Update on every conversation and stream_output change
  useEffect(() => {

    // If not streaming, have the focus be on the demo_input
    if (!is_streaming && demo_input_ref.current) {
      // Autofocus on demo_input
      demo_input_ref.current.focus()
    }

    // Scroll down to the bottom at all times
    if (scroll_ref.current) {
      const { scrollHeight, clientHeight } = scroll_ref.current;
      scroll_ref.current.scrollTop = scrollHeight - clientHeight; // Scroll to the bottom
    }
  }, [conversation, stream_output]);


  return (
    <>
      
      {(() => {
        switch (page) {

          // FORM
          case 0: {
            return <div className="space-y-6">


              {/* Input fields */}
              <div className="space-y-4">
                <div className="text-white text-sm font-semibold mb-6">
                  Step 1. Personalize your demo
                </div>
                <div>
                  <div className="block text-sm font-medium leading-6 text-white">
                    Full name
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      autoComplete="name"
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3 outline-none placeholder:text-white/30"
                      // placeholder="John Doe"
                      value={full_name}
                      onChange={(e) => handle_user_input("full_name", e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <div className="block text-sm font-medium leading-6 text-white">
                    Email address
                  </div>
                  <div className="mt-2">
                    <input
                      type="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3 outline-none placeholder:text-white/30"
                      // placeholder="john@acme.com"
                      value={email_address}
                      onChange={(e) => handle_user_input("email_address", e.target.value)}
                    />
                  </div>
                  {email_address
                  ? <div className="mt-2 block text-sm font-medium leading-6 text-gray-400">
                    Please make sure to provide your work email.
                  </div>
                  : <></>}
                </div>

                <div>
                  <div className="block text-sm font-medium leading-6 text-white">
                    Organization name
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      autoComplete="organization"
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3 outline-none placeholder:text-white/30"
                      // placeholder="Acme Inc."
                      value={organization}
                      onChange={(e) => handle_user_input("organization", e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <div className="block text-sm font-medium leading-6 text-white">
                    Your title
                  </div>
                  <div className="mt-2">
                    <input
                      type="text"
                      autoComplete="organization-title"
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3 outline-none placeholder:text-white/30"
                      // placeholder="Director of IT"
                      value={position}
                      onChange={(e) => handle_user_input("position", e.target.value)}
                    />
                  </div>
                </div>
              </div>
              
              {/* Bottom line */}
              <div className="flex space-x-4">

                {/* Terms & policies */}
                <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
                  <div className="flex h-6 items-center">
                    <Switch
                      checked={is_agreed}
                      onChange={set_is_agreed}
                      className={classNames(
                        is_agreed ? 'bg-indigo-500' : 'bg-white/10',
                        'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'
                      )}
                    >
                      <span className="sr-only">Agree to policies</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          is_agreed ? 'translate-x-3.5' : 'translate-x-0',
                          'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </div>
                  <div>
                    <Switch.Label className="text-sm leading-6 text-gray-400 cursor-pointer">
                      By selecting this, you agree to our{' '}
                    </Switch.Label>
                    <Link to="https://app.vansec.com/terms" target="_blank" className="font-semibold text-indigo-400 text-sm leading-6">
                      Terms&nbsp;of&nbsp;Service
                    </Link>
                    <Switch.Label className="text-sm leading-6 text-gray-400 cursor-pointer">
                      {" "}and{" "}
                    </Switch.Label>
                    <Link to="https://app.vansec.com/privacy" target="_blank" className="font-semibold text-indigo-400 text-sm leading-6">
                      Privacy&nbsp;Policy
                    </Link>
                    <Switch.Label className="text-sm leading-6 text-gray-400 cursor-pointer">
                      .
                    </Switch.Label>
                  </div>

                  
                
                  
                </Switch.Group>

                <div className="flex justify-center items-center">
                  <button
                    type="submit"
                    disabled={!(full_name && email_address && organization && is_agreed)}
                    className={classNames(
                      full_name && email_address && organization && is_agreed ? 'bg-indigo-500 hover:bg-indigo-400' : 'bg-white/5 hover:bg-white/10',
                      'block w-full rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'
                    )}
                    onSubmit={form_to_scenario} // testing
                    onClick={form_to_scenario} // testing

                  >
                    Continue
                  </button>
                </div>
                
                {/* <ArrowRightCircleIcon className="w-12 h-12 text-white/30" /> */}

              </div>
              

              

              {/* Error message */}
              {error_message
              ? <div className="mt-6 flex space-x-2 items-start">
                  <ExclamationTriangleIcon className="pt-[2px] w-4 h-4 text-red-400 h-full"/>
                  <div className="text-sm font-medium text-red-400">{error_message}</div>
                </div>
              : <></>}

              {/* Submit button */}
              {/* <div className="mt-6 flex justify-center">
                <button
                  type="submit"
                  disabled={!(full_name && email_address && organization && is_agreed)}
                  className={classNames(
                    full_name && email_address && organization && is_agreed ? 'bg-indigo-600 hover:bg-indigo-500' : 'bg-white/5 hover:bg-white/10',
                    'block w-full rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-1/3'
                  )}
                  onSubmit={form_to_scenario}
                  onClick={form_to_scenario}
                >
                  Continue
                </button>
              </div> */}

            </div>
          }
          // SCENARIOS
          case 1: {
            return <div>

              <div>
                <label className="block text-sm leading-6 text-white flex">
                  <span className="font-semibold">Step 2. Select scenario</span>
                  <span className="flex relative items-center">
                    <InformationCircleIcon className="peer cursor-pointer ml-1 w-4 h-4" />
                    <span className="peer-hover:opacity-100 peer-hover:z-50 bg-gray-800 px-4 py-2 text-sm text-gray-100 rounded-md absolute sm:left-8 sm:top-0 -left-24 top-8 sm: w-56 -z-10 opacity-0 mx-auto font-normal">
                      Select the scenario for the demo simulation.
                    </span>
                  </span>
                </label>
                <div className="mt-4">
                  <RadioGroup value={simulation_scenario_options.find(simulation_scenario_option => simulation_scenario_option === scenario)} onChange={(e) => handle_user_input("scenario", e)}>
                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                      {simulation_scenario_options.map((simulation_scenario_option) => (
                        <RadioGroup.Option
                          key={simulation_scenario_option}
                          value={simulation_scenario_option}
                          className={({ active }) =>
                            classNames(
                              active ? 'border-white/10 ring-2 ring-white/10' : 'border-white/10',
                              'relative flex cursor-pointer rounded-lg border bg-white/5 px-4 py-3 shadow-sm focus:outline-none'
                            )
                          }
                        >
                          {({ checked, active }) => (
                            <>
                              <span className="flex flex-1">
                                <span className="flex flex-col">
                                  <RadioGroup.Label as="span" className="block text-sm font-medium text-white">
                                    {simulation_scenario_map[simulation_scenario_option].title}
                                  </RadioGroup.Label>
                                  <RadioGroup.Description 
                                    as="span" 
                                    className="mt-3 text-sm font-normal text-gray-300 overflow-hidden"
                                    style={{
                                      display: '-webkit-box',
                                      WebkitLineClamp: '8', 
                                      WebkitBoxOrient: 'vertical',
                                      maxHeight: '10rem',
                                      lineHeight: '1.25rem'
                                    }}
                                  >
                                    {simulation_scenario_map[simulation_scenario_option].description}
                                  </RadioGroup.Description>
                                </span>
                              </span>
                              <CheckCircleIcon
                                className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-white')}
                                aria-hidden="true"
                              />
                              <span
                                className={classNames(
                                  active ? 'border' : 'border-2',
                                  checked ? 'border-white/10' : 'border-transparent',
                                  'pointer-events-none absolute -inset-px rounded-lg'
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
              </div>

              <div className="mt-6 flex justify-center items-center">
                <button
                  type="submit"
                  // disabled={!scenario}
                  className={classNames(
                    scenario ? 'bg-indigo-500 hover:bg-indigo-400' : 'bg-white/5 hover:bg-white/10',
                    'block w-full rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'
                  )}
                  onSubmit={scenario_to_main}
                  onClick={scenario_to_main}
                >
                  Start demo
                </button>
              </div>

            </div>
          }
          // MAIN
          case 2: {
            return <div className="text-sm text-white h-full">

              <div className="">
                {`Subject: ${conversation.length > 0 ? "Re: " : ""}${simulation_scenario_map[scenario].subject_line}`}
              </div>

              <div className="mt-4 space-y-4 h-5/6 overflow-y-auto scrollbar scrollbar scrollbar-thumb-white/5 scrollbar-track-transparent" ref={scroll_ref}>

                {/* Conversation */}
                <div className="space-y-4">
                  {conversation.map((message, index) => {
                    return <div key={index} className="space-y-2">

                      {/* Name & email */}
                      <div className="flex space-x-2">
                        <div className="font-medium">{index % 2 === 0 ? "Debbie Parsons" : full_name}</div>
                        <div className="text-gray-500">{index % 2 === 0 ? get_simulation_email_address(email_address, scenario) : email_address}</div>
                      </div>
                      
                      {/* Text area */}
                      <div 
                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3 outline-none placeholder:text-white/30"
                      >
                        {message.content.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  })}
                </div>

                {/* Stream output */}
                {is_streaming
                ? <div className="space-y-2">

                  {/* Name & email */}
                  <div className="flex space-x-2">
                    <div className="font-medium">Debbie Parsons</div>
                    <div className="text-gray-500">{get_simulation_email_address(email_address, scenario)}</div>
                  </div>

                  {/* Text area */}
                  <div className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3 outline-none placeholder:text-white/30">
                    {/* {stream_output } */}
                    {stream_output.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>

                </div>
                : <></>}
                

                {/* Demo input */}
                {!is_streaming && conversation.length < 8
                ? <div className="space-y-2">

                  {/* Name & email */}
                  <div className="flex space-x-2">
                    <div className="font-medium">{full_name}</div>
                    <div className="text-gray-500">{email_address}</div>
                  </div>

                  {/* Text area */}
                  <textarea
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3 outline-none placeholder:text-white/30 resize-none overflow-hidden transition duration-150 ease-in-out "
                    // style={{ minHeight: '50px' }}
                    onInput={adjust_height}
                    value={demo_input}
                    onChange={(e) => handle_user_input("demo_input", e.target.value)}
                    placeholder=""
                    ref={demo_input_ref}
                  />

                  <div className="flex justify-end items-center">
                    <button
                      type="submit"
                      disabled={!demo_input}
                      className={classNames(
                        demo_input ? 'bg-indigo-500 hover:bg-indigo-400' : 'bg-white/5 hover:bg-white/10',
                        'block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'
                      )}
                      onSubmit={() => send_text(false)} 
                      onClick={() => send_text(false)}

                    >
                      Reply
                    </button>
                  </div>

                </div> 
                : <></>}
              

              </div>
            </div>
          }
          default: {
            return <></>
          }
        }
      })()}

    </>
  )
}


export default Demo