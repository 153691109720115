

import { io } from "socket.io-client";



let socket;

export const get_socket = () => {
  if (!socket) {
    // socket = io("localhost:5000", { transports: ['websocket'] }) // DEV
    socket = io({ transports: ['websocket'] }) // PROD
  }
  return socket;
}


export const delete_socket = () => {
  socket = null
}



export const abort_action = async () => {
  
  
}