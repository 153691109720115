
import React, { useState, useEffect } from 'react'
import { validate_email_address, validate_organization_domain } from '../../libs/validate'
import axios from 'axios'

const UnsubscribePage = ({
  
} : {
  
}) => {

  // States
  const [email_address, set_email_address] = useState("")
  const [error_message, set_error_message] = useState("")
  const [success_message, set_success_message] = useState("")

  // Status 
  const [is_submitted, set_is_submitted] = useState(false)
  const [is_awaiting, set_is_awaiting] = useState(false)

  // Submit function
  const submit = async () => {
    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate email address
    if (!validate_email_address(email_address)) {
      // set_email_address("")

      // Show error message
      set_error_message("Invalid email address")

      // End of the line
      return
    }

    // Submit request to app.vansec.com
    const post_request_res = await axios.post(`/api/unsubscribe`, {
      email_address: email_address
    })

    if (!post_request_res.data.success) {
      
    }

    // Set is_submitted to true
    set_is_submitted(true)
    set_success_message("You have successfully unsubscribed")
  }


  useEffect(() => {

  }, [])

  return (
    <>
      <div className="absolute w-full h-full">
        {/* Main */}
        <div className="flex min-h-full flex-col bg-transparent pt-16 pb-12">
          <main className="mx-auto flex w-full max-w-2xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                Please enter your email address to unsubcribe
              </label>
              <div className="mt-1 flex space-x-2">
                <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  value={email_address}
                  onChange={(e) => {set_error_message(""); set_email_address(e.target.value)}}
                />
                <button
                  type="submit"
                  disabled={is_submitted || !email_address}
                  className={is_submitted || !email_address ? "block rounded-md bg-blue-300 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 flex-1" : "block rounded-md bg-blue-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"}
                  onClick={submit}
                >
                  Unsubscribe
                </button>
              </div>
              {error_message
              ? <div className="flex space-x-2 items-start mt-4">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="pt-[2px] w-4 h-4 text-red-400 h-full">
                      <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="text-sm font-medium text-red-400">{error_message}</div>
                </div>
              : <></>}
              {success_message
              ? <div className="flex space-x-2 items-start mt-4">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="pt-[2px] w-4 h-4 text-green-400 h-full">
                      <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="text-sm font-medium text-green-400">{success_message}</div>
                </div>
              : <></>}
              
            </div>
          </main>
          <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
            <nav className="flex justify-center space-x-4">
              <a href="mailto:support@vansec.com" target="_self" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Contact Support
              </a>
              {/* <span className="inline-block border-l border-gray-300" aria-hidden="true" />
              <Link href="#" target="_self" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Status
              </Link>
              <span className="inline-block border-l border-gray-300" aria-hidden="true" />
              <Link href="#" target="_self" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Twitter
              </Link> */}
            </nav>
          </footer>
        </div>
      </div>
    </>
  )
}

export default UnsubscribePage