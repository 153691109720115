
import React, { useContext, useEffect } from 'react'

const Custom404 = ({
  
} : {
  
}) => {


  useEffect(() => {

  }, [])

  return (
    <>
      <div className="absolute w-full h-full">
        {/* Background */}
        <div className="relative w-full isolate bg-white -z-10">
          <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
            <svg
              className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
              viewBox="0 0 1155 678"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
                fillOpacity=".3"
                d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
              />
              <defs>
                <linearGradient
                  id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                  x1="1155.49"
                  x2="-78.208"
                  y1=".177"
                  y2="474.645"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#1A202C" />
                  <stop offset={1} stopColor="#1A202C" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          
          <div className="absolute inset-x-0 top-[calc(100vh-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100vh-40rem)] top-[120px]">
            <svg
              className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
              viewBox="0 0 1155 678"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                fillOpacity=".3"
                d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
              />
              <defs>
                <linearGradient
                  id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                  x1="1155.49"
                  x2="-78.208"
                  y1=".177"
                  y2="474.645"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#1A202C" />
                  <stop offset={1} stopColor="#1A202C" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        {/* Main */}
        <div className="flex min-h-full flex-col bg-transparent pt-16 pb-12">
          <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
            <div className="flex flex-shrink-0 justify-center">
              <a href="/" target="_self" className="inline-flex">

              </a>
            </div>
            <div className="py-16">
              <div className="text-center">
                <p className="text-base font-semibold text-gray-600">404</p>
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found.</h1>
                <p className="mt-2 text-base text-gray-500">Sorry, we couldn't find the page you're looking for.</p>
                <div className="mt-6">
                  <a href='/' target="_self">
                    <span className="text-base font-medium text-gray-600 hover:text-gray-500">
                      Go back home
                      <span aria-hidden="true"> &rarr;</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </main>
          <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
            <nav className="flex justify-center space-x-4">
              <a href="mailto:support@vansec.com" target="_self" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Contact Support
              </a>
              {/* <span className="inline-block border-l border-gray-300" aria-hidden="true" />
              <Link href="#" target="_self" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Status
              </Link>
              <span className="inline-block border-l border-gray-300" aria-hidden="true" />
              <Link href="#" target="_self" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Twitter
              </Link> */}
            </nav>
          </footer>
        </div>
      </div>
    </>
  )
}

export default Custom404