import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition, Listbox, Switch } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'

import { ChevronDownIcon } from '@heroicons/react/20/solid'

import { Link } from "react-router-dom";
import { validate_email_address, validate_organization_domain } from '../libs/validate'
import axios from 'axios'
import { mixpanel_client_track } from '../libs/mixpanelClient';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const options = [
  // { id: 1, value: '0-10' },
  // { id: 2, value: '10-50' },
  // { id: 3, value: '50-200' },
  // { id: 4, value: '200-1000' },
  // { id: 5, value: '1000-5000' },
  // { id: 6, value: '5000+' },
  { id: 1, value: '0-100' },
  { id: 2, value: '101-500' },
  { id: 3, value: '501-1000' },
  { id: 4, value: '1001-5000' },
  { id: 5, value: '5001-10000' },
  { id: 6, value: '10000+' },
]






const RequestAccessModal = ({
  is_open,
  set_is_open
} : {
  is_open : boolean
  set_is_open : any // todo
}) => {


  // States
  const [full_name, set_full_name] = useState("")
  const [email_address, set_email_address] = useState("")
  const [organization, set_organization] = useState("")
  const [position, set_position] = useState("")
  // const [number_of_employees, set_number_of_employees] = useState(options[0])
  const [is_agreed, set_is_agreed] = useState(false)
  const [error_message, set_error_message] = useState("")

  // Status 
  const [is_submitted, set_is_submitted] = useState(false)
  const [error_message_is_displayed, set_error_message_is_displayed] = useState(false)
  const [is_awaiting, set_is_awaiting] = useState(false)


  // Handle user input
  const handle_user_input = (type, value) => {
    switch(type) {
      case "full_name": {
        set_full_name(value)

        // Always break
        break
      }
      case "email_address": {
        set_email_address(value)

        // Always break
        break
      }
      case "organization": {
        set_organization(value)

        // Always break
        break
      }
      case "position": {
        set_position(value)

        // Always break
        break
      }
      // case "number_of_employees": {
      //   set_number_of_employees(value)

      //   // Always break
      //   break
      // }
      default: {

        // Always break
        break
      }
    }

    // Always hide error message and reset it to empty string
    set_error_message("")
    set_error_message_is_displayed(false)
  }


  // Reset variables
  const reset = () => {
    set_is_awaiting(false)
  }

  // Submit function
  const submit = async () => {
    // Set awaiting
    set_is_awaiting(true)

    // START OF USER INPUT CHECK

    // Validate email address
    if (!validate_email_address(email_address)) {
      reset()

      // Show error message
      set_error_message("Invalid email address")
      set_error_message_is_displayed(true)

      // End of the line
      return
    }

    const organization_domain = email_address.split("@")[1]

    // Validate email address
    if (!validate_organization_domain(organization_domain)) {
      reset()

      // Show error message
      set_error_message("Please enter your work email")
      set_error_message_is_displayed(true)

      // End of the line
      return
    }

    // Submit request to app.vansec.com
    const post_request_res = await axios.post(`/api/index/form`, {
      type: "request_demo",
      data: {
        full_name: full_name,
        email_address: email_address,
        organization: organization,
        position: position,
      }
    })

    if (!post_request_res.data.success) {
      
    }

    // Set is_submitted to true
    set_is_submitted(true)

    // Mixpanel tracking
    mixpanel_client_track("www_request_access_modal_submitted", null)
  }
  


  useEffect(() => {

    if (is_open) {
      // Mixpanel tracking
      mixpanel_client_track("www_request_access_modal_opened", null)
    }
    
  }, [is_open])
  
  return (
    <Transition.Root show={is_open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={set_is_open}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">

              {!is_submitted
              ? <div className="isolate bg-white px-6 py-8 sm:py-8 lg:px-8">
                  {/* Background */}
                  <div
                    className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
                    aria-hidden="true"
                  >
                    <div
                      className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                      style={{
                        clipPath:
                          'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                      }}
                    />
                  </div>

                  {/* Top */}
                  <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-600 sm:text-2xl">Book a demo</h2>
                    {/* <p className="mt-2 text-base leading-8 text-gray-600">
                      Our team will reach out to you within 12 hours for demo and 
                    </p> */}
                  </div>

                  {/* Input fields */}
                  <div className="mx-auto mt-12 max-w-xl sm:mt-12">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-2">

                      <div className="sm:col-span-2">
                        <label htmlFor="full-name" className="block text-sm font-semibold leading-6 text-gray-900">
                          Full name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="full-name"
                            id="full-name"
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={full_name}
                            onChange={(e) => handle_user_input("full_name", e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            autoComplete="email"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={email_address}
                            onChange={(e) => handle_user_input("email_address", e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label htmlFor="organization" className="block text-sm font-semibold leading-6 text-gray-900">
                          Organization
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="organization"
                            id="organization"
                            autoComplete="organization"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={organization}
                            onChange={(e) => handle_user_input("organization", e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label htmlFor="organization" className="block text-sm font-semibold leading-6 text-gray-900">
                          Title
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="organization-title"
                            id="position"
                            autoComplete="organization-title"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={position}
                            onChange={(e) => handle_user_input("position", e.target.value)}
                          />
                        </div>
                      </div>

                      {/* <div className="sm:col-span-2">
                        <Listbox value={number_of_employees} onChange={(e) => handle_user_input("number_of_employees", e)}>
                          {({ open }) => (
                            <>
                              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Number of employees</Listbox.Label>
                              <div className="relative mt-2">
                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white px-3.5 py-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  <span className="block truncate">{number_of_employees.value}</span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {options.map((person) => (
                                      <Listbox.Option
                                        key={person.id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                          )
                                        }
                                        value={person}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                              {person.value}
                                            </span>

                                            {selected ? (
                                              <span
                                                className={classNames(
                                                  active ? 'text-white' : 'text-indigo-600',
                                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                                )}
                                              >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                      </div> */}
                      
                      
                      <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
                        <div className="flex h-6 items-center">
                          <Switch
                            checked={is_agreed}
                            onChange={set_is_agreed}
                            className={classNames(
                              is_agreed ? 'bg-indigo-600' : 'bg-gray-200',
                              'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                            )}
                          >
                            <span className="sr-only">Agree to policies</span>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                is_agreed ? 'translate-x-3.5' : 'translate-x-0',
                                'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                              )}
                            />
                          </Switch>
                        </div>
                        <div>
                          <Switch.Label className="text-sm leading-6 text-gray-600 cursor-pointer">
                            By selecting this, you agree to our{' '}
                          </Switch.Label>
                          <Link to="https://app.vansec.com/terms" target="_blank" className="font-semibold text-indigo-600 text-sm leading-6">
                            Terms&nbsp;of&nbsp;Service
                          </Link>
                          <Switch.Label className="text-sm leading-6 text-gray-600 cursor-pointer">
                            {" "}and{" "}
                          </Switch.Label>
                          <Link to="https://app.vansec.com/privacy" target="_blank" className="font-semibold text-indigo-600 text-sm leading-6">
                            Privacy&nbsp;Policy
                          </Link>
                          <Switch.Label className="text-sm leading-6 text-gray-600 cursor-pointer">
                            .
                          </Switch.Label>
                        </div>
                        
                      </Switch.Group>
                    </div>

                    {error_message_is_displayed
                    ? <div className="flex space-x-2 items-start mt-4">
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="pt-[2px] w-4 h-4 text-red-400 h-full">
                            <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                          </svg>
                        </div>
                        <div className="text-sm font-medium text-red-400">{error_message}</div>
                      </div>
                    : <></>}

                    <div className="mt-6">
                      <button
                        type="submit"
                        disabled={!(full_name && email_address && organization && is_agreed)}
                        className={full_name && email_address && organization && is_agreed ? "block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : "block w-full rounded-md bg-indigo-300 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"}
                        onSubmit={submit}
                        onClick={submit}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              : <div className="isolate bg-white px-6 py-8 sm:py-8 lg:px-8">
                  {/* Background */}
                  <div
                    className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
                    aria-hidden="true"
                  >
                    <div
                      className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                      style={{
                        clipPath:
                          'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                      }}
                    />
                  </div>

                  {/* Top */}
                  <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-600 sm:text-2xl">Book a demo</h2>
                  </div>

                  {/* Input fields */}
                  <div className="mx-auto mt-6 max-w-xl sm:mt-6">
                    <div className="font-regular tracking-tight text-gray-600 text-md">

                      We will reach out to you within 12 hours at {email_address} to schedule the demo. Or if you prefer, you can also find a time for the demo directly using the link below.

                    </div>

                    <div className="mt-8">
                      <Link
                        to="https://calendly.com/vansec/vansec-demo"
                        target="_blank"
                        className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Book a demo slot
                      </Link>
                    </div>
                  </div>
                </div>
              }
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default RequestAccessModal;
